import { ReferenceField, useGetList, ReferenceInput, SelectInput, List, Datagrid, NumberField, FunctionField, TextField } from "react-admin";
import { Typography } from '@mui/material';
import { PostPagination } from './Trainee';
import { TableHead, TableRow, TableCell, TextField as muiTextField } from '@mui/material';
const validateStartDate = (values) => {
    const errors = {};

    if (!values) {
        errors.rotation = "Must select a rotation"
    }
    return errors
}
const departmentStatisticsFilter = [
    <ReferenceInput label="Rotation" reference="rotations" filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }} source="departmentStatisticsByRotationIDAndDepartmentName.rotationID" allowEmpty alwaysOn>
        <SelectInput label="Rotation Date" allowEmpty />
    </ReferenceInput>
];

const DatagridHeader = () => {
    return (
        <TableHead >
            <TableRow>
                <TableCell key={"departmentID"}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Department"} </Typography>
                </TableCell>
                {process.env.REACT_APP_MULTISITE == "1" &&
                    <TableCell key={"siteID"}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Office"} </Typography>
                    </TableCell>}
                <TableCell key={"numberOfFirstYearsAssigned"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"Trainees assigned"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"First year / Second year"} </Typography>
                </TableCell>
                <TableCell key={"numberOfSeatsAvailable"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"Seats"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Available (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"totalPrefrences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"Preferences requested"} </Typography>
                </TableCell>
                <TableCell key={"numberOfFirstPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"1st Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfSecondPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"2nd Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfThirdPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"3rd Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfFourthPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"4th Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfFifthPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"5th Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfSixthPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"6th Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfNoPreferencesAssigned"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"No Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"(Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfManualOverrides"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Manual Overrides"} </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};

export const DepartmentStatisticsList = () => {
    const { isLoading, data } = useGetList('rotations');
    data?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = data?.filter((rotation) => (rotation.cohortsIndex <= 11));
    const currentRotation = currentCohorts?.at(-1)

    if (!isLoading) {
        return (
            <List resource="departmentStatistics" pagination={<PostPagination />} filter={{ departmentStatisticsByRotationIDAndDepartmentName: { rotationID: currentRotation?.id } }} actions={false} empty={false} >
                <Datagrid bulkActionButtons={false} sx={{
                    '& .column-departmentID': { textAlign: 'left' },
                }} header={<DatagridHeader />}>
                    <ReferenceField source="departmentID" reference="departments" label="Department" link="show" />
                    {process.env.REACT_APP_MULTISITE == "1" &&
                        <ReferenceField source="departmentID" reference="departments" label="Department" link={false} >
                            <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        </ReferenceField>}
                    <FunctionField source="numberOfFirstYearsAssigned" label="Seats" render={(record) => { return String(record.numberOfFirstYearsAssigned) + " / " + String(record.numberOfSecondYearsAssigned) + "" }} />
                    <FunctionField source="numberOfSeatsAvailable" label="Seats" render={(record) => { return String(record.numberOfSeatsAvailable) + " (" + String(record.numberOfSeatsAssigned) + ")" }} />
                    <FunctionField source="totalPrefrences" label="Preferences requested" render={(record) => { return String(record.numberOfFirstPreferences + record.numberOfSecondPreferences + record.numberOfThirdPreferences + record.numberOfFourthPreferences + record.numberOfFifthPreferences + record.numberOfSixthPreferences) }} />
                    <FunctionField source="numberOfFirstPreferences" label="1st Preferences assigned" render={(record) => { return String(record.numberOfFirstPreferences) + " (" + String(record.numberOfFirstPreferencesAssigned) + ")" }} />
                    <FunctionField source="numberOfSecondPreferences" label="2nd Preferences assigned" render={(record) => { return String(record.numberOfSecondPreferences) + " (" + String(record.numberOfSecondPreferencesAssigned) + ")" }} />
                    <FunctionField source="numberOfThirdPreferences" label="3rd Preferences assigned" render={(record) => { return String(record.numberOfThirdPreferences) + " (" + String(record.numberOfThirdPreferencesAssigned) + ")" }} />
                    <FunctionField source="numberOfFourthPreferences" label="4th Preferences assigned" render={(record) => { return String(record.numberOfFourthPreferences) + " (" + String(record.numberOfFourthPreferencesAssigned) + ")" }} />
                    <FunctionField source="numberOfFifthPreferences" label="5th Preferences assigned" render={(record) => { return String(record.numberOfFifthPreferences) + " (" + String(record.numberOfFifthPreferencesAssigned) + ")" }} />
                    <FunctionField source="numberOfSixthPreferences" label="6th Preferences assigned" render={(record) => { return String(record.numberOfSixthPreferences) + " (" + String(record.numberOfSixthPreferencesAssigned) + ")" }} />
                    <FunctionField source="numberOfNoPreferencesAssigned" label="No Preferences assigned" render={(record) => { return "(" + String(record.numberOfNoPreferencesAssigned) + ")" }} />
                    <NumberField source="numberOfManualOverrides" label="Manual overrides" sortable={false} />
                </Datagrid>
            </List>
        );
    }
}
