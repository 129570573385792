import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
    DateField,
    Show,
    SimpleShowLayout,
    required,
    useGetOne,
    useGetIdentity,
    useGetList,
} from "react-admin";
import { Typography } from '@mui/material';
import { BackButton } from './BackButton';
import { ordinal_suffix_of, wordy_ordinal } from './variable_preferences';
import { DepartmentPlusSiteField } from "./Trainee";


const validatePreference = (values) => {
    const errors = {};
    console.warn("values", values)
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        if (!values.departmentID[priority]) {
            errors['departmentID.' + priority] = "Must enter a " + wordy_ordinal(priority + 1) + " preference"
        } else {
            for (let previous_priority = 0; previous_priority < priority; previous_priority++) {
                if (values.departmentID[previous_priority] == values.departmentID[priority]) {
                    errors['departmentID.' + priority] = "Cannot enter the same department twice"
                }
            }
        }
    }


    return errors
}
export const ManyPreferenceCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/');
        refresh();
    };
    const { data, isLoading } = useGetIdentity();
    const traineeData = useGetOne('trainees', { id: data?.id + "::" + data?.id })
    const sites = useGetList("sites");
    const rotationsList = useGetList('rotations');
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentRotation = rotations?.at(-1)
    console.log(currentRotation)
    if (!isLoading && !sites.isLoading && !traineeData.isLoading) {
        // Check if there is a wildcard site
        var wildcard_site_id = ""
        for (const site in sites?.data) {
            console.log(sites?.data[site])
            if (sites?.data[site].wildcard) {
                console.log(sites?.data[site].wildcard)
                wildcard_site_id = sites?.data[site].id
            }
        }
        const onError = onSuccess;
        var preferencesInput = []
        for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
            if (wildcard_site_id) {
                console.log([traineeData.data?.siteID, wildcard_site_id])
                preferencesInput.push(<ReferenceInput label={ordinal_suffix_of(priority + 1) + " Preference"} source={"departmentID." + priority} reference="departments" filter={{ listDepartments: { filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: wildcard_site_id } }] } } }} perPage={1000}>
                    <SelectInput optionText="name" label={ordinal_suffix_of(priority + 1) + " Preference"} />
                </ReferenceInput >)
            } else {
                preferencesInput.push(<ReferenceInput label={ordinal_suffix_of(priority + 1) + " Preference"} source={"departmentID." + priority} reference="departments" filter={{ departmentsBySiteIDAndName: { siteID: traineeData.data?.siteID } }} perPage={1000}>
                    <SelectInput optionText="name" label={ordinal_suffix_of(priority + 1) + " Preference"} />
                </ReferenceInput >)
            }
        }
        const deadlineDate = new Date(currentRotation?.deadline).toLocaleDateString('en-GB', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        });
        return (
            <Create {...props} mutationOptions={{ onError, onSuccess, meta: { many: true } }} actions={<BackButton />}>
                <SimpleForm validate={validatePreference}>
                    <Typography display="block">
                        Please add your preferences for your next seat rotation.
                    </Typography>
                    <br />
                    <Typography display="block">
                        You will need to complete all {process.env.REACT_APP_NUM_OF_PREFRENCES} preferences and you must choose {process.env.REACT_APP_NUM_OF_PREFRENCES} different departments.
                    </Typography>
                    <br />
                    <Typography display="block">
                        If you would like to check which seats you put as preferences in an earlier rotation, please check your homepage.
                    </Typography>
                    <br />
                    <Typography display="block">
                        Once you save your preferences you will not be able to change these, so please make sure that you are happy with your seat preferences.
                    </Typography>
                    <br />
                    <Typography>
                        Please submit your preferences by {deadlineDate}
                    </Typography>
                    {preferencesInput}
                </SimpleForm>
            </Create>
        )
    }
}

export const PreferenceCreate = (props) => (
    <Create {...props} actions={<BackButton />}>
        <SimpleForm>
            <ReferenceInput label="Department" source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                <SelectInput optionText="name" label="Department" />
            </ReferenceInput>
            <NumberInput source="priority" defaultValue="1" />
        </SimpleForm>
    </Create>
);

export const PreferenceEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();


    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/trainees');
        refresh();
    };
    return (
        <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<BackButton />}>
            <SimpleForm>
                <ReferenceInput source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={100}>
                    <SelectInput source="name" label="Department" optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>)
};

export const PreferenceShow = (props) => {
    return (
        <Show {...props} actions={<BackButton />}>
            <SimpleShowLayout>
                <ReferenceField source="rotationID" reference="rotations" label="Rotation" />
                <ReferenceField source="departmentID" reference="departments" label="Department" />
                <NumberField source="priority" />
            </SimpleShowLayout>
        </Show>)
};




const PreferenceList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="departmentID" reference="departments" label="Department">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="traineeID" reference="trainees" label="Trainee" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="priority" />
                <EditButton />
            </Datagrid>
        </List>
    );
};