export const updateTrainee = /* GraphQL */ `
  mutation UpdateTrainee(
    $input: UpdateTraineeInput!
    $condition: ModelTraineeConditionInput
  ) {
    updateTrainee(input: $input, condition: $condition) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        createdAt
        updatedAt
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      traineeDiary
      createdAt
      updatedAt
    }
  }
`;
export const updateTraineeAdmin = /* GraphQL */ `
  mutation UpdateTrainee(
    $input: UpdateTraineeInput!
    $condition: ModelTraineeConditionInput
  ) {
    updateTrainee(input: $input, condition: $condition) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      manualOverrideID
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      traineeDiary
      hrComments
      firstSeatSupervisor
      secondSeatSupervisor
      thirdSeatSupervisor
      fourthSeatSupervisor
      createdAt
      updatedAt
    }
  }
`;
export const updateRotation = /* GraphQL */ `
  mutation UpdateRotation(
    $input: UpdateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    updateRotation(input: $input, condition: $condition) {
      id
      type
      startDate
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      cohortsIndex
      deadline
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfNoPreferences
      numberOfManualOverrides
      published
      createdAt
      updatedAt
    }
  }
`;
export const createRotation = /* GraphQL */ `
  mutation CreateRotation(
    $input: CreateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    createRotation(input: $input, condition: $condition) {
      id
      type
      startDate
      cohortsIndex
      deadline
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;