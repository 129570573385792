import * as React from 'react';
import { AppBar } from 'react-admin';
import { Box, useMediaQuery } from '@mui/material'
import logo from '../images/logo.png';
import clientLogo from '../images/nortonrose.svg'


export const OptiSeatAppBar = () => {
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    if (isSmall) {
        return (<AppBar>
            <Box flex={1} />
            <img src={logo} style={{ maxWidth: "40%" }} />
            <Box flex={1} />
        </AppBar>)
    } else {
        return (
            <AppBar>
                <Box flex={1} />
                <Box flex={1} />
                <img src={clientLogo} style={{ maxHeight: "30px", zIndex: "1" }} />
                <Box flex={1} />
                <img src={logo} style={{ maxWidth: "180px" }} />
                <Box flex={1} />
                <Box flex={1} />
            </AppBar>)
    }
};
