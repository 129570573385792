import { Card, Box, CircularProgress } from '@mui/material'
import * as React from 'react';
import { API, Auth } from 'aws-amplify';

import {
    useGetIdentity,
    useShowController,
    RecordContextProvider,
    SimpleShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    FunctionField,
    ReferenceField,
    useGetOne,
    SingleFieldList,
    useRecordContext,
    ChipField,
    WrapperField,
    List, NumberField,
    usePermissions,
    useStore,
    Button,
    useRedirect,
    DateField,
    useGetList,
    ListContextProvider,
    ShowButton,
    BooleanField,
    EditButton,
    useRefresh,
    useNotify,
    SimpleList,
    Labeled,
    Toolbar,
    SaveButton,
    RichTextField
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';

import { SeatField } from './Trainee';
import {
    Typography, Grid, TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    Button as MuiButton
} from '@mui/material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from './Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { NewPreferenceButton, PostPagination, TraineePreferencesList, ManualOverrideField } from "./Trainee"
import { formatDate, indexToYear } from "./Rotation"
import { DataGridWithIndex } from './DataGridWithIndex'
import { DepartmentStatisticsList } from "./DepartmentStatistics"
import { ordinal_suffix_of, get_num_of_preferences } from "./variable_preferences"

export const RotationPreferences = ({ priority, traineeRecord, links }) => {
    const record = useRecordContext();
    const filterValues = { preferencesByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };

    return (
        <ReferenceManyField reference="preferences" target="preferencesByTraineeIDAndRotationID.traineeID.rotationID" filter={filterValues} record={traineeRecord}>
            <SingleFieldList linkType={false}>
                <TraineePreferencesList priority={priority} clickable={false} links={links} />
            </SingleFieldList>
        </ReferenceManyField>
    )
}


export const RotationSeat = ({ traineeRecord }) => {

    const record = useRecordContext();
    const seatsFilterValues = { seatsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };
    const publishedSeatsFilterValues = { publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };

    if (record.published) {
        return (
            <ReferenceManyField label="Published Seats" reference='publishedSeats' target='publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={publishedSeatsFilterValues} record={traineeRecord} >
                <SingleFieldList linkType={false}>
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>)
    } else {
        return (
            <ReferenceManyField label="Assigned Seat" reference='seats' target='seatsByTraineeIDAndRotationID.traineeID.rotationID' filter={seatsFilterValues} record={traineeRecord}  >
                <SingleFieldList linkType={false}>
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>)
    }
}

export const RotationPublishedSeat = ({ traineeRecord }) => {

    const record = useRecordContext();
    const publishedSeatsFilterValues = { publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };

    return (
        <ReferenceManyField label="Published Seats" reference='publishedSeats' target='publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={publishedSeatsFilterValues} record={traineeRecord} >
            <SingleFieldList linkType={false}>
                <SeatField />
            </SingleFieldList>
        </ReferenceManyField>)
}
export const RotationPreferencesList = ({ traineeRecord, links }) => {
    const { permissions } = usePermissions();
    const record = useRecordContext();
    if (!traineeRecord) {
        traineeRecord = record;
    }
    const id = record.cohortID;
    const { isLoading, data } = useGetOne("rotations", { id })
    if (!isLoading) {
        // console.log("record", record)
        var preferenceColumns = []
        for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
            preferenceColumns.push(
                <WrapperField label={ordinal_suffix_of(priority + 1) + " Preference"} >
                    <RotationPreferences priority={priority + 1} traineeRecord={traineeRecord} links={links} />
                </WrapperField>)
        }
        return (
            <List label="Rotation" resource="rotations" actions={false} pagination={<PostPagination />} filter={{ rotationsByTypeAndStartDate: { type: "Rotation", startDate: { ge: data?.startDate } } }}>
                <Datagrid bulkActionButtons={false} sx={{
                    '& .RaDatagrid-rowCell': { textAlign: 'left' },
                    '& .RaDatagrid-headerCell': { textAlign: 'left' },
                    '& .RaDatagrid-row': { height: '45px' }
                }}>
                    <FunctionField source="startDate" render={record => formatDate(record.startDate)} label="Rotation Date" />
                    {preferenceColumns}
                    {!permissions?.includes("Admins") && <NewPreferenceButton traineeRecord={traineeRecord} rotationID={false} />}
                    {permissions?.includes("Admins") &&
                        <WrapperField label="Seat" >
                            <RotationSeat traineeRecord={traineeRecord} />
                        </WrapperField>}
                    {!permissions?.includes("Admins") &&
                        <WrapperField label="Seat" >
                            <RotationPublishedSeat traineeRecord={traineeRecord} />
                        </WrapperField>}
                </Datagrid>
            </List>
        )
    }
}

export const RotationPreferencesListMobile = ({ links, record }) => {
    const departmentList = useGetList('departments', { filter: { departmentsByTypeAndName: { type: "Department" } }, pagination: { page: 1, perPage: 1000 } });
    var departmentMap = {}
    for (const department in departmentList?.data) {
        departmentMap[departmentList?.data[department].id] = departmentList?.data[department].name
    }

    const preferencesList = useGetList('preferences', { filter: { preferencesByTraineeIDAndRotationID: { traineeID: record?.id } }, pagination: { page: 1, perPage: 1000 } });
    const { isLoading, data } = useGetOne("rotations", { id: record?.cohortID })
    if (!isLoading && !preferencesList.isLoading && !departmentList.isLoading) {
        const preferences = preferencesList?.data
        var rotationsToPreferencesMap = {}
        for (const preference in preferences) {
            if (preferences[preference]?.rotationID in rotationsToPreferencesMap) {
                rotationsToPreferencesMap[preferences[preference]?.rotationID].push({ name: departmentMap[preferences[preference]?.departmentID], priority: preferences[preference]?.priority })
            } else {
                rotationsToPreferencesMap[preferences[preference]?.rotationID] = [{ name: departmentMap[preferences[preference]?.departmentID], priority: preferences[preference]?.priority }]
            }
        }
        for (const rotation in rotationsToPreferencesMap) {
            rotationsToPreferencesMap[rotation]?.sort((a, b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))
        }

        return (
            <List sx={{ width: "100%" }} label="Rotation" resource="rotations" actions={false} pagination={false} filter={{ rotationsByTypeAndStartDate: { type: "Rotation", startDate: { ge: data?.startDate } } }}>
                <SimpleList secondaryText={record => rotationsToPreferencesMap[record.id]?.length ? "Preferences: " + rotationsToPreferencesMap[record.id].map((department) => department?.name).join(", ") : ""} tertiaryText={record => record?.PublishedSeats?.items[0]?.departmentID ? departmentMap[record?.PublishedSeats?.items[0]?.departmentID] : "Not allocated yet"}
                    primaryText={record => formatDate(record.startDate)} linkType={false} />
            </List>
        )
    }
}

const TraineeBySeatsHeader = ({ rotationsList }) => {
    var rotationsHeaders = []
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 4));

    for (const rotation in currentCohorts) {
        rotationsHeaders.push(
            <TableCell key={currentCohorts[rotation].startDate}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} >{formatDate(currentCohorts[rotation].startDate)} </Typography>
                {currentCohorts[rotation].published ? null : <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} >(not published)</Typography>}
            </TableCell>)
    }
    return (
        <TableHead >
            <TableRow>
                <TableCell ></TableCell>
                <TableCell key={"name"}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Trainee"} </Typography>
                </TableCell>
                <TableCell key={"cohortID"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Cohort"} </Typography>
                </TableCell>
                {process.env.REACT_APP_MULTISITE == "1" &&
                    <TableCell key={"siteID"}>
                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Office"} </Typography>
                    </TableCell>
                }
                <TableCell key={"desiredGraduatingSeatID"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Desired Qualification Area"} </Typography>
                </TableCell>
                {rotationsHeaders}
                <TableCell key={"manualOverride"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Manual override"} </Typography>
                </TableCell>
                <TableCell key={"litigationSeatCompleted"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Corporate Seat Completed"} </Typography>
                </TableCell>
                <TableCell key={"bankingSeatCompleted"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Banking Seat Completed"} </Typography>
                </TableCell>
                <TableCell >

                </TableCell>
            </TableRow>
        </TableHead>
    )
};


export const TraineeBySeats = (props) => {

    const refresh = useRefresh();
    const notify = useNotify();
    const [assignSeatsLoading, setAssignSeatsLoading] = React.useState(false)

    const rotationsList = useGetList('rotations');
    var columns = []

    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 4));
    const currentRotation = currentCohorts?.at(-1)

    for (const rotation in currentCohorts?.slice(0, -1)) {
        columns.push(
            <ReferenceManyField label={currentCohorts[rotation].startDate} reference="preferences" target="publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentCohorts[rotation].id } } }} link={false}>
                <SingleFieldList linkType={false} >
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>)
    }
    if (!currentRotation?.published) {
        columns.push(
            <ReferenceManyField label={currentRotation?.startDate} reference="preferences" target="seatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ seatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotation?.id } } }} link={false}>
                <SingleFieldList linkType={false}>
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>
        )
    } else {
        columns.push(
            <ReferenceManyField label={currentRotation?.startDate} reference="preferences" target="publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotation?.id } } }} link={false}>
                <SingleFieldList linkType={false}>
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>)
    }

    const assignSeats = () => {
        const apiName = 'assignSeats';
        const path = '/assignseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': currentRotation.id
                }
            };
            setAssignSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setAssignSeatsLoading(false); refresh() }).catch(error => { setAssignSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }
    const [page, setPage] = useStore('trainee.page', 1);
    const [perPage, setPerPage] = useStore('trainee.perPage', 100);
    const sort = { field: 'name', order: 'ASC' };
    const { data, pageInfo, isLoading } = useGetList('trainees', {
        filter: { traineesByTypeAndName: { type: 'Trainee', filter: { or: currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) } } },
        pagination: { page, perPage },
        sort,
    });
    if (isLoading) { return (<></>) }
    const { hasNextPage, hasPreviousPage } = pageInfo
    // const { hasNextPage, hasPreviousPage } = pageInfo;
    return (
        <ListContextProvider value={{ data, page, perPage, setPage, setPerPage, hasNextPage, hasPreviousPage, sort }} >
            <Box component="block" >
                <Button size='large' variant="outlined" color="primary" onClick={assignSeats} disabled={currentRotation?.published || assignSeatsLoading}>
                    {assignSeatsLoading ? <CircularProgress size="26px" /> : "Assign seats"}
                </Button>
            </Box>
            <div style={{ marginTop: "20px" }}>
                <Card  >
                    <DataGridWithIndex sort={sort} sx={{
                        '& .column-id': { textAlign: 'left' },
                        '& .column-name': { textAlign: 'left' },
                    }} header={<TraineeBySeatsHeader rotationsList={rotationsList} />}>
                        <TextField source="name" sortable={true} />
                        <ReferenceField source="cohortID" reference="rotations" label="Cohort" link={false}>
                            <FunctionField label="Cohort" render={(record) => indexToYear(record.cohortsIndex)} />
                        </ReferenceField>
                        {process.env.REACT_APP_MULTISITE == "1" &&
                            <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                        <ReferenceField label="Desired Qualification Area" source="desiredGraduatingSeatID" reference='departments' link="show" />
                        {columns}
                        <ManualOverrideField {...props} label="Manual Override" />
                        <BooleanField source="litigationSeatCompleted" />
                        <BooleanField source="bankingSeatCompleted" />
                        <ShowButton resource='trainees' />
                    </DataGridWithIndex>
                </Card>
                <PostPagination />
            </div>
        </ListContextProvider>
    );
};

export const ShowUserProfile = (data) => {
    const showController = useShowController({ resource: 'trainees', id: data.id + "::" + data.id });

    const traineeRecord = showController?.record;
    const linkGenerator = (record, resource) => `/departments/${record.id}/show`

    return (
        <RecordContextProvider value={showController?.record}>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="name" />
                        <ReferenceField source="cohortID" reference="rotations" label="Training Contract Start Date" />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="email" label="Username" />
                        <ReferenceField label="Desired Qualification Area" source="desiredGraduatingSeatID" reference='departments' >
                            <TextField source='name' />
                        </ReferenceField>
                    </SimpleShowLayout>
                    {/* <SimpleShowLayout >
                <Grid container spacing={0}p={0}>
                <Grid item xs={3} p={0} spacing={0}>
                 <SimpleShowLayout  sx={{padding: 0}}>
                 <ReferenceField source="desiredGraduatingSeatID" reference='departments' label="Desired Seat upon Graduation"> 
                <TextField source='name' />
                </ReferenceField>
                </SimpleShowLayout>
                </Grid>
                <Grid item xs={3}p={2}>
                <EditButton resource='trainees' />
                </Grid>
                </Grid> 
                </SimpleShowLayout>  */}
                </Grid>
            </Grid>
            <SimpleShowLayout>
                <Typography display="block">
                    Welcome to Optiseat and we look forward to helping you with choosing your seat preferences for the next 6 months.  Our department section will let you know the different departments that are offering trainee seats and how many seats are available, you can also find more information on the sort of work trainees do.  Once you have researched the options available, add your preferences below and save your choices.
                </Typography>
                <Typography display="block">
                    Once everyone has submitted their preferences, your talent team will review and then click our algorithm into action.  Considering the constraints of individual preferences and the availability of seats, our algorithm generates the best possible outcome of seat allocation for you and all of your trainee colleagues.
                </Typography>
                <Typography display="block">
                    We wish you the best in your next seat and your future career as a lawyer.  If you have any questions, please do not hesitate to contact xxxxx xxxxxx in your talent team
                </Typography>
                <Typography display="block">
                    Please report any bugs or issues with the platform to <a href="mailto:admin@optiseat.co.uk">admin@optiseat.co.uk</a> and we will get it fixed for you.
                </Typography>

                <RotationPreferencesList traineeRecord={traineeRecord} links={linkGenerator} />
                <Grid container >
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="First Seat Supervisor">
                                <TextField source="firstSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Second Seat Supervisor">
                                <TextField source="secondSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Third Seat Supervisor">
                                <TextField source="thirdSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Fourth Seat Supervisor">
                                <TextField source="fourthSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
                <WrapperField label="Your Comments">
                    <RichTextField source="traineeDiary" label="Your Comments" />
                    <EditButton resource='trainees' label="Edit" />
                </WrapperField>
                <RichTextField source="hrComments" label="Grad Team Comments" />
            </SimpleShowLayout>
        </RecordContextProvider>
    )
}

export const ShowUserProfileMobile = (data) => {
    const showController = useShowController({ resource: 'trainees', id: data.id + "::" + data.id });
    const redirect = useRedirect();
    const rotationsList = useGetList('rotations');
    var columns = []

    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11));
    const currentRotation = currentCohorts?.at(-1)

    const currentPreferencesList = useGetList('preferences', { filter: { preferencesByTraineeIDAndRotationID: { traineeID: showController?.record?.id, rotationID: { 'eq': currentRotation?.id } } } });
    const linkGenerator = (record, resource) => `/departments/${record.id}/show`
    return (
        <RecordContextProvider value={showController?.record}>

            <SimpleShowLayout>
                <TextField source="name" />
                <ReferenceField source="cohortID" reference="rotations" label="Training Contract Start Date" />

                <TextField source="email" label="Username" />


                <Typography >
                    Welcome to Optiseat and we look forward to helping you with choosing your seat preferences for the next 6 months.  Our department section will let you know the different departments that are offering trainee seats and how many seats are available, you can also find more information on the sort of work trainees do.  Once you have researched the options available, add your preferences below and save your choices.
                </Typography>
                <Typography >
                    Once everyone has submitted their preferences, your talent team will review and then click our algorithm into action.  Considering the constraints of individual preferences and the availability of seats, our algorithm generates the best possible outcome of seat allocation for you and all of your trainee colleagues.
                </Typography>
                <Typography >
                    We wish you the best in your next seat and your future career as a lawyer.  If you have any questions, please do not hesitate to contact xxxxx xxxxxx in your talent team
                </Typography>
                <Typography>
                    If you have any questions relating the platform please contact <a href="mailto:admin@optiseat.co.uk">admin@optiseat.co.uk</a>
                </Typography>
                <Labeled label='Allocated Seats' sx={{ width: "100%", fontSize: "14pt" }} >
                    <RotationPreferencesListMobile links={linkGenerator} record={showController?.record} />
                </Labeled>

                {!currentPreferencesList?.data?.length && <Box textAlign='center'><MuiButton size='small' variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => { redirect('create', 'preferences', 1, {}, { record: { traineeID: data.id, rotationID: currentRotation.id } }) }}>
                    Add Preferences for upcoming rotation
                </MuiButton></Box>}
                <TextField source="firstSeatSupervisor" emptyText="Not assigned yet" />
                <TextField source="secondSeatSupervisor" emptyText="Not assigned yet" />
                <TextField source="thirdSeatSupervisor" emptyText="Not assigned yet" />
                <TextField source="fourthSeatSupervisor" emptyText="Not assigned yet" />
                <WrapperField label="Your Comments">
                    <RichTextField source="traineeDiary" label="Your Comments" />
                    <EditButton resource='trainees' label="Edit" />
                </WrapperField>
                <RichTextField source="hrComments" label="Grad Team Comments" />
            </SimpleShowLayout>
        </RecordContextProvider>
    )
}

const GoToTraineeViewButton = () => {

    const redirect = useRedirect();
    const record = useRecordContext();
    const [, setFilter] = useStore('trainee.filter')
    return (
        <Button onClick={(e) => { setFilter(record.id); redirect('list', 'trainees') }} label='Edit' />
    )
}


const AdminDashboard = (props) => {
    const [isRotationsOverviewAccordionOpen, setIsRotationsOverviewAccordionOpen] = useStore('isRotationsOverviewAccordionOpen', false);
    const [isSeatsOverviewAccordionOpen, setIsSeatsOverviewAccordionOpen] = useStore('isSeatsOverviewAccordionOpen', false);
    const [isDepartmentsOverviewAccordionOpen, setIsDepartmentsOverviewAccordionOpen] = useStore('isDepartmentsOverviewAccordionOpen', false);
    var numberOfPreferences = []
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        numberOfPreferences.push(<FunctionField label={ordinal_suffix_of(priority + 1) + " Preferences"} render={(record) => get_num_of_preferences(priority + 1, record)} />)
    }
    return (
        <Card sx={{ mt: 2 }} >
            <Accordion expanded={isRotationsOverviewAccordionOpen} onChange={() => { setIsRotationsOverviewAccordionOpen(!isRotationsOverviewAccordionOpen) }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Rotations Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List resource='rotations' actions={null} filter={{ rotationsByTypeAndStartDateAdmin: { type: "Rotation" } }} perPage={100} pagination={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            '& .column-startDate': { textAlign: 'left' },
                        }} >
                            <FunctionField source="startDate" render={record => formatDate(record.startDate)} label="Rotation Date" />
                            {numberOfPreferences}

                            <NumberField source="numberOfNoPreferences" label="No Preferences" sortable={false} />
                            <NumberField source="numberOfManualOverrides" label="Manual overrides" sortable={false} />
                            {/* <GoToTraineeViewButton /> */}
                        </Datagrid>
                    </List>
                </AccordionDetails>


            </Accordion>
            <Accordion expanded={isSeatsOverviewAccordionOpen} onChange={() => { setIsSeatsOverviewAccordionOpen(!isSeatsOverviewAccordionOpen) }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Seats Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isSeatsOverviewAccordionOpen &&
                        <TraineeBySeats />}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={isDepartmentsOverviewAccordionOpen} onChange={() => { setIsDepartmentsOverviewAccordionOpen(!isDepartmentsOverviewAccordionOpen) }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Departments Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isDepartmentsOverviewAccordionOpen &&
                        <DepartmentStatisticsList />
                    }
                </AccordionDetails>
            </Accordion>
        </Card>
    )
}

export const Dashboard = () => {
    const { data, isLoading } = useGetIdentity();
    const { permissions } = usePermissions();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    if (!isLoading) {
        if (!permissions?.includes("Admins")) {
            if (isSmall) {
                return (
                    <ShowUserProfileMobile {...data} />
                )
            } else {
                return (
                    <ShowUserProfile {...data} />)
            }
        } else {
            return (<AdminDashboard />)
        }
    }
    return (
        <Card></Card>
    )
}