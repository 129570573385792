import * as React from 'react';
import { View, Image, useTheme, Authenticator } from '@aws-amplify/ui-react';
import { Alert } from '@aws-amplify/ui-react';

import logo from '../images/logo.png';
import clientLogo from '../images/nortonrose.svg'


export const authenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>


        <Image
          alt="Client logo"
          src={clientLogo}
        />
        <Image
          alt="OptiSeat logo"
          src={logo}
        />
      </View>
    );
  },
  ForceNewPassword: {
    FormFields() {
      return (
        <>
          <Authenticator.ForceNewPassword.FormFields />
          <Alert variation="info">Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number and one symbol.</Alert >

        </>
      );
    },
  },
}
